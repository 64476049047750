
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIAlert from "@/views/resources/documentation/element-ui/notice/message-box/Alert.vue";
import EUIConfirm from "@/views/resources/documentation/element-ui/notice/message-box/Confirm.vue";
import EUIPrompt from "@/views/resources/documentation/element-ui/notice/message-box/Prompt.vue";
import EUICustomization from "@/views/resources/documentation/element-ui/notice/message-box/Customization.vue";
import EUIUseHTMLString from "@/views/resources/documentation/element-ui/notice/message-box/UseHTMLString.vue";
import EUIDistinguishingCancelAndClose from "@/views/resources/documentation/element-ui/notice/message-box/DistinguishingCancelAndClose.vue";
import EUICenteredContent from "@/views/resources/documentation/element-ui/notice/message-box/CenteredContent.vue";

export default defineComponent({
  name: "message-box",
  components: {
    EUIAlert,
    EUIConfirm,
    EUIPrompt,
    EUICustomization,
    EUIUseHTMLString,
    EUIDistinguishingCancelAndClose,
    EUICenteredContent
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("MessageBox");
    });
  }
});
